<script>
//横棒グラフのコンポーネントクラス
//このファイルはscriptのみ記載する
import { HorizontalBar } from 'vue-chartjs'
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
  name: 'HorizontalBarChart',
  extends: HorizontalBar,
  props: ['data', 'options'],
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>